import { useEffect, useState } from "react"
import { DefaultTableModel, ITableModel } from "../models/orders/table.model"
import { DataGrid, GridColDef, GridPagination, GridPreProcessEditCellProps, GridRenderCellParams, GridRenderEditCellParams, GridRowEditStartReasons, GridRowEditStopReasons, GridRowModel, GridRowModesModel, GridToolbarContainer, useGridApiContext, useGridApiRef } from "@mui/x-data-grid"
import { deleteVendor, submitVendors, updateVendors } from "../services/order.service"
import { Pagination, TablePagination } from "@mui/material"

type Props = {
  className?: string,
  tableState: ITableModel,
  vendors: any,
  states: any,
  setSelectedRows?: any,
  selectedRows?:any,
  checkboxSelection?:true
}

type EditRowProps = {
  params: GridRenderEditCellParams
}

type RowProps = {
  params: GridRenderCellParams
}

const tableStyles = {
    '&.MuiDataGrid-root': {
      fontFamily: '"Open Sans", sans-serif !important',
      fontSize: '14px !important',
      color: '#000 !important',
      border: 'none'
    },
    '.MuiDataGrid-main':{
      marginTop: '24px'
    },
    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
      marginBottom: '0'
    },
    '.MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
      padding: '5px 15px !important',
      borderRight: 'solid 1px #AFAFAF'
    },
    '.css-yrdy0g-MuiDataGrid-columnHeaderRow, .MuiDataGrid-row, .MuiDataGrid-virtualScrollerRenderZone, .MuiDataGrid-columnHeadersInner': {
      width: 'calc(100%) !important'
    },
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#F6F6F6'
    },
    '.MuiDataGrid-columnHeadersInner': {
      width: 'calc(100% - 5.59px) !important'
    },
    '.MuiDataGrid-columnHeadersInner > [role="row"]': {
      width: '100% !important'
    },
    '.MuiDataGrid-columnHeader:last-of-type': {
      border: 'none !important'
    },
    '.MuiDataGrid-iconSeparator': {
      display: 'none'
    },
    '.MuiDataGrid-cell': {
      backgroundColor: '#FDFDFD',
      borderColor: '#DFDFDF'
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700
    },
    '.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter': {
      float: 'right'
    },
}

const VendorTable: React.FC<Props> = ({className, tableState, vendors, states, selectedRows, setSelectedRows}) => {
    const {pagination, ...dpm} = DefaultTableModel;
    const [pageState, setPageState] = useState<any>(dpm);
    const [rows, setRows] = useState<any[]>([])
    const [rowsModel, setRowsModel] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [editing, setEditing] = useState(false);
    const [editId, setEditId] = useState(0);
    const [publicApi, setPublicApi] = useState(useGridApiRef());
    const [errorMessage, setErrorMessage] = useState('');
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
      if(Object.keys(publicApi.current).length != 0) {
        setRowCount(publicApi.current.getSortedRows().length);
      }
    }, [publicApi.current])

    function RenderVendorCell({params}: RowProps) {
        const { id, row, value } = params; 
        const [renderedText, setRenderedText] = useState('N/A');
        useEffect(() => {
          if(vendors && row.state) {
            var allCompanies = vendors.filter((v: any) => String(v.stateId) === String(row.state))[0]?.searchCompanies;
            if(allCompanies) {
              setRenderedText(allCompanies.filter((v: any) => String(v.searcher_SCOMP_ID) === String(value))[0]?.searcher_SCOMP_Company ?? 'N/A');
            }
          }
        }, [vendors, row.state]);
        return <span>{renderedText}</span>;
    }

    const RenderEditVendorCell = ({params}: EditRowProps) => {
      const { id, value, row, error, field} = params;
      const apiRef = useGridApiContext();
      const [searchCompanies, setSearchCompanies] = useState([]); 
      const handleChange = (event: any) => {
          apiRef.current.setEditCellValue({ id, field, value: event.target.value }, event);
      };
      useEffect(() => {
        if(vendors && row.state) {
          var searchCompanies = vendors.filter((v: any) => String(v.stateId) === String(row.state))[0]?.searchCompanies ?? [];
          setSearchCompanies(searchCompanies);
        }
      }, [vendors, row.state])
      useEffect(() => {
        if(error) {
          setIsValid(false);
        }
      }, [error])
      return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
          <select
            className='form-select form-select-sm form-select-solid'
            tabIndex={params && params.hasFocus ? 0 : -1}
            value={value}
            onChange={handleChange}
          >
            <option key="0" value="0">Choose Vendor</option>
            {searchCompanies.map((item: any, index: any) => (
              <option key={item.searcher_SCOMP_ID} value={item.searcher_SCOMP_ID}>{item.searcher_SCOMP_Company}</option>
            ))}
          </select>
          <div>
            <span className="help is-danger">{error}</span>
          </div>
        </div> 
      )
    }

    // function RenderProductCell({params}: any) {
    //     const { id, row, value } = params;
    //     const [renderedText, setRenderedText] = useState('N/A');
    //     useEffect(() => {
    //       if(vendors && row.state) {
    //         var products = vendors.filter((v: any) => String(v.stateId) === String(row.state))[0]?.products;
    //         if(products) {
    //           setRenderedText(products.filter((p: any) => String(p.product_id) === String(value))[0]?.product_name ?? 'N/A');
    //         }
    //       }
    //     }, [vendors, row.state])
    //     return <span>{renderedText}</span>;
    // }

    // const RenderEditProductCell = ({params}: any) => {
    //   const { id, error, value, row, field} = params;
    //   const apiRef = useGridApiContext();
    //   const [products, setProducts] = useState([]);
    //   useEffect(() => {
    //     if(vendors) {
    //       var products = vendors[0].products;
    //       setProducts(products);
    //     }
    //   }, [vendors])
    //   useEffect(() => {
    //     if(error) {
    //       setIsValid(false);
    //     }
    //   }, [error]);
    //   const handleChange = (event: any) => {
    //       apiRef.current.setEditCellValue({ id, field, value: event.target.value }, event);
    //   };
    //   return (
    //     <div className="d-flex flex-column align-items-center justify-content-center w-100">   
    //       <select
    //         className='form-select form-select-sm form-select-solid'
    //         tabIndex={params && params.hasFocus ? 0 : -1}
    //         value={value}
    //         onChange={handleChange}
    //       >
    //         <option key="0" value="0">Choose Product</option>
    //         {products?.map((item: any, index: any) => (
    //             <option key={item.product_id} value={item.product_id}>{item.product_name}</option>
    //         ))}
    //       </select>
    //       <div>
    //         <span className="help is-danger">{error}</span>
    //       </div>
    //     </div>
    //   )
    // }

    function RenderStateCell({params}: any) {
        const { id, row, value } = params;
        const [renderedText, setRenderedText] = useState('');
        useEffect(() => {
          if(value) {
            setRenderedText(states.filter((s: any) => String(s.stateId) === String(value))[0]?.stateName ?? 'N/A');
          }
          else {
            setRenderedText('N/A');
          }
        }, [value]);
        return <span>{renderedText}</span>;
    }

    const RenderEditStateCell = ({params}: any) => {
        const { id, value, error, row, field} = params;
        const apiRef = useGridApiContext();
        const [availableStates, setAvailableStates] = useState<any>([]);
        const handleChange = (event: any) => {
            apiRef.current.setEditCellValue({ id, field, value: event.target.value }, event);
        };
        useEffect(() => {
          if(vendors) {
            var state = vendors;
            setAvailableStates(state);
          }
        }, [vendors])
        useEffect(() => {
          if(error) {
            setIsValid(false);
          }
        }, [error])
        return (
          <div className="d-flex flex-column align-items-center justify-content-center w-100">   
            <select
              className='form-select form-select-sm form-select-solid'
              tabIndex={params && params.hasFocus ? 0 : -1}
              value={value}
              onChange={handleChange}
            >
              <option key="0" value="0">Choose State</option>
              {availableStates.map((item: any, index: any) => (
                <option key={item.stateId} value={item.stateId}>{item.stateName}</option>
              ))}
            </select>
            <div>
              <span className="help is-danger">{error}</span>
            </div>
          </div>
        )
    }

    function RenderCountiesCell({params}: any) {
        const { id, value, row } = params; 
        const [merged, setMerged] = useState();
        useEffect(() => {
          if(row.state && states) {
            if(value) {
              var counties: any = [];
              value.forEach((item: any) => {
                const allCounties = states.filter((s: any) => String(s.stateId) === String(row.state))[0]?.counties;
                const selectedCounties = allCounties?.filter((c: any) => String(c.countyId) === String(item))
                selectedCounties?.forEach((v: any) => {
                  counties.push(v.countyName)
                })
              })
              if(counties && counties.length != 0) {
                setMerged(counties.join(", "));
              }
            }
          }
        }, [row.state])
        return <span>{merged ?? 'N/A'}</span>;
    }

    const RenderEditCountiesCell = ({params}: any) => {
        const { id, value, row, field} = params;
        const apiRef = useGridApiContext();
        const [counties, setCounties] = useState<any>([]);
        useEffect(() => {
          if(row.state && states) {
            setCounties(states.filter((s: any) => String(s.stateId) === String(row.state))[0]?.counties ?? []);
          }
        }, [row.state])
        const handleChange = (event: any) => {
            if(!value.includes(event.target.value)) {
              if(value[0] === "") {
                apiRef.current.setEditCellValue({ id, field, value: [event.target.value] }, event);
              }
              else {
                apiRef.current.setEditCellValue({ id, field, value: [...value, event.target.value].sort((a: number, b: number) => a - b)}, event);
              }
            }
            else {
                var newValue = value;
                var index = newValue.indexOf(event.target.value);
                if(index != -1) {
                    newValue.splice(index, 1);
                }
                apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
            }
        };
        return (
            <select
              className='form-select form-select-sm form-select-solid'
              tabIndex={params && params.hasFocus ? 0 : -1}
              value={value || []}
              multiple
              onChange={handleChange}
            >
              {counties?.map((item: any, index: any) => (
                <option key={item.countyId} value={item.countyId}>{item.countyName}</option>
              ))}
            </select>
        )
    }

    const handleDeleteClick = (id: any, api: any, isLocal: boolean = false) => {
      if(!isLocal) {
        setErrorMessage('');
        deleteVendor(id).then(result => {
          if(result?.message) {
            var rows = api.current.getSortedRows();
            var rowId = rows.filter((row: any) => String(row.recordId) === String(id))[0]?.id;
            if(rowId) {
              api.current.updateRows([{ id: rowId, _action: "delete"}])
              setRowCount(api.current.getSortedRows().length);
              setEditing(false);
              setEditId(0);
            }
          }
          else if(result?.error) {
            setErrorMessage(result.error);
          }
        });
      }
      else {
        if(id) {
          api.current.updateRows([{ id: id, _action: "delete"}])
          setEditing(false);
          setEditId(0);
          setRowCount(api.current.getSortedRows().length);
        }
      }
    };

    const setRowEditable = (id: number) => {
      setEditing(true);
      setEditId(id);
      setRowsModel({
        ...rowsModel,
        [id]: {
          mode: "edit",
          fieldToFocus: "state"
        }
      });
    }

    const RenderActionsCell = ({params}: any) => {
      const { id, value, api, row, field} = params;
      const apiRef = useGridApiContext();
      useEffect(() => {
        if(apiRef) {
          setPublicApi(apiRef);
        }
      }, [apiRef])
      return (
        <div className="d-flex justify-content-center align-items-center py-3">
          { 
            <>
              {editId === id ?
                <button type="button" className="btn btn-primary m-0" onClick={() => apiRef.current.stopRowEditMode({ id: id })} style={{width: "auto !important", minWidth: "unset"}}><span className="fa fa-save"></span></button>
              :
                <button type="button" className="btn btn-primary m-0" onClick={() => setRowEditable(id)} style={{width: "auto !important", minWidth: "unset"}}><span className="fa fa-edit"></span></button>
              }
              {row.update && 
                <button type="button" disabled={editing} className="btn btn-primary m-0" onClick={() => copyRow(id, apiRef)} style={{width: "auto !important", minWidth: "unset"}}><span className="fa fa-copy"></span></button>
              }
              <button type="button" className="btn btn-danger m-0" onClick={() => (row.update) ? handleDeleteClick(row.recordId, apiRef) : handleDeleteClick(row.id, apiRef, true) } style={{width: "auto !important", minWidth: "unset"}}><span className="fa fa-remove"></span></button>
            </>
          }
          
        </div>
      )
    }

    const emptyRow = {
      vendor: '',
      state: '',
      counties: '',
      update: false,
      actions: false
    }

    const addRow = (api: any) => {
      setIsValid(false);
      var rows = api.current.getSortedRows();
      var newRow = {...emptyRow, id: (rows.length != 0) ? rows[rows.length - 1]?.id + 1 : 1};
      api.current.updateRows([newRow]);
      setRowCount(api.current.getSortedRows().length);
      setTimeout(() => {
        setRowEditable(newRow.id);
      }, 100);
    }

    const copyRow = (id: any, api: any) => {
      setIsValid(false);
      var rows = api.current.getSortedRows();
      var newRows = [...rows];
      var copy = newRows.filter((r: any) => r.id == id)[0];
      copy = {...copy, update: false, id: rows[rows.length - 1].id + 1};
      api.current.updateRows([copy]);
      setRowCount(api.current.getSortedRows().length);
      // tableState.settings.updatePageState("rows", newRows);
      setRowEditable(copy.id);
    }

    const updateRow = async (newRow: GridRowModel) => {
      var doDelete = false;
      if(isValid) {
        setErrorMessage('');
        var result;
        if(newRow.update) {
          result = await updateVendors(newRow);
        }
        else {
          result = await submitVendors(newRow);
        }
        if(result.message) {
          var addRow = {
            ...result.message,
            update: true,
            id: newRow.id
          };
          publicApi.current.updateRows([addRow]);
          setEditing(false);
          setEditId(0);
          return addRow;
        }
        else if(result.error) {
          setErrorMessage(result.error);
          if(!newRow.update) {
            doDelete = true;
          }
        }
      }
      setTimeout(() => {
        if(doDelete) {
          publicApi.current.updateRows([{id: newRow.id, _action: "delete"}]);
        }
        setEditing(false)
        setEditId(0);
      }, 100)
      return newRow
    };
    
    function vendorValidation(vendor: string): Promise<boolean> {
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          const valid = vendor && vendor !== "0"
          resolve(valid ? null : "Vendor is required");
        }, 50);
      });
    }

    function stateValidation(state: string): Promise<boolean> {
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          const valid = state && state !== "0"
          resolve(valid ? null : "State is required");
        }, 100)
      });
    }

    function productValidation(product: string): Promise<boolean> {
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          const valid = product && product !== "0"
          resolve(valid ? null : "Product is required");
        }, 150);
      });
    }

    const validateVendor = async (params: GridPreProcessEditCellProps) => {
      const errorMessage = await vendorValidation(params.props.value);
      if(!errorMessage)setIsValid(true);
      return { ...params.props, error: errorMessage };
    }

    const validateState = async (params: GridPreProcessEditCellProps) => {
      const errorMessage = await stateValidation(params.props.value);
      if(!errorMessage)setIsValid(true);
      return { ...params.props, error: errorMessage };
    }

    const validateProduct = async (params: GridPreProcessEditCellProps) => {
      const errorMessage = await productValidation(params.props.value);
      if(!errorMessage)setIsValid(true);
      return { ...params.props, error: errorMessage };
    }

    const validateCounties = (params: GridPreProcessEditCellProps) => {
      return { ...params.props };
    }

    const handleEditStop = (params: any, event?: any) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    }

    const handleEditStart = (params: any, event?: any) => {
      if (params.reason === GridRowEditStartReasons.cellDoubleClick) {
        event.defaultMuiPrevented = true;
      }
    }

    const columns: GridColDef[] = [
        { field: 'state', headerName: 'State', editable: true, flex: 1, minWidth: 200, renderCell: (params) => <RenderStateCell params={params} />, renderEditCell: (params) => <RenderEditStateCell params={params} />, preProcessEditCellProps: validateState },
        { field: 'vendor', headerName: 'Search Vendor', editable: true, flex: 1, minWidth: 200, renderCell: (params) => <RenderVendorCell params={params} />, renderEditCell: (params) => <RenderEditVendorCell params={params}/>, preProcessEditCellProps: validateVendor },
        { field: 'counties', headerName: 'Counties', editable: true, flex: 1, minWidth: 200, renderCell: (params) => <RenderCountiesCell params={params} />, renderEditCell: (params) => <RenderEditCountiesCell params={params} />, preProcessEditCellProps: validateCounties },
        { field: 'actions', headerName: 'Actions', align: "center", flex: 1, minWidth: 200, renderCell: (params) => <RenderActionsCell params={params} />}
    ];

    useEffect(() => {
        if(tableState) {
          const {settings, pagination, rows, ...newPageState} = tableState;
          setRows(rows);
          setPageState (newPageState); 
        } 
      },[tableState, tableState.rows])

    return (
      <div className={`card ${className}`}>
        <div className='card-body'>
          <div className='table-responsive h-auto'>
          <DataGrid
            autoHeight
            getRowHeight={() => 'auto'}
            headerHeight={50}
            pagination
            paginationMode={'client'}
            rowsPerPageOptions={[10,25,50,100]}
            onPageSizeChange={(newPageSize) => tableState.settings.updatePageState('pageSize',newPageSize)}
            onPageChange={(newPage) => tableState.settings.updatePageState('page',newPage)}
            {...pageState}
            filterMode='server'
            editMode="row"
            rows={rows}
            rowCount={rowCount}
            columns={columns}
            rowModesModel={rowsModel}
            onRowModesModelChange={(row: GridRowModesModel) => {
              setRowsModel(row); 
            }}
            components={{
              Toolbar: () => {
                const apiRef = useGridApiContext();
                return (<GridToolbarContainer sx={{height: 50, py: "10px"}}>
                  <div className='d-flex justify-content-start align-items-center w-50'>
                    {errorMessage &&
                      <div className='mb-lg-5 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{errorMessage}</div>
                      </div>
                    }
                  </div>
                  <div className='d-flex justify-content-end align-items-center w-50'>
                    <button disabled={editing} type="button" className="btn btn-primary m-0" onClick={() => addRow(apiRef)}>Add Vendor</button>
                  </div>
                </GridToolbarContainer>)
              },
              Pagination: (props: any) => {
                return <GridPagination showFirstButton showLastButton />
              }
            }}
            onRowEditStop={handleEditStop}
            onRowEditStart={handleEditStart}
            processRowUpdate={async (newRow: GridRowModel, oldRow: GridRowModel) => {
              const addRow = await updateRow(newRow);
              return addRow;
            }}
            onProcessRowUpdateError={(error) => console.log(error)}
            experimentalFeatures={{ newEditingApi: true }}
            sx={tableStyles}
          />
          </div>
        </div>
      </div>
    )
}

export {VendorTable}