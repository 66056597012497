import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { request } from 'http'
import { useAuth } from '../../contexts/auth.context'

const ContactTestPage = () => {
  const {currentUser} = useAuth();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    script.setAttribute('type', 'text/javascript');
    document.body.appendChild(script);

    script.addEventListener('load', () => {
        //@ts-ignore
        if(window.hbspt){
            //@ts-ignore
            hbspt.forms.create({
            region: "na1",        
            portalId: "23399414",        
            formId: "4de9bb4f-0f22-43b6-a720-cc9e9bf58eea",
            target: '#hubspotForm',
            onFormReady: () => {
              var requesterCompany = document.getElementsByName("requested_by_search_company")[0];
              if(requesterCompany && currentUser?.agency_agencyName){
                requesterCompany.setAttribute("value", currentUser.agency_agencyName);
              }
              var requesterUser = document.getElementsByName("requested_by_user_")[0];
              if(requesterUser && currentUser?.user_name){
                requesterUser.setAttribute("value", currentUser.user_name);
              }
            }
          });
        }
    });

    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }

  }, [currentUser])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        CONTACT US
      </PageTitle>
      <div className='card bg-white min-h-750px' style={{border: 'solid 1px #E8E8E8'}}>
      <div className='card-header pt-3 pb-2 d-flex align-items-center'>
        <h3 className='card-title align-items-start flex-row'>
          <span className='card-label fw-light fs-3 text-primary'>Contact Us</span></h3>
      </div>

      <div className='card-body py-6'>
      <p>
      if you have any questions or would like to learn more on how MaestroX can support your business growth, please email us on <a href="mailto:support@Maestrox.com">support@MaestroX.com</a> of via the form below.
      </p>
      <p>To request portal access for additional user at your organization, please include the First name + Last name + email address of each user in the message box below:
      </p>
      <div id="hubspotForm"></div>
      </div>
    </div>
    </>
  )
}

const ContactTestWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>CONTACT US</PageTitle>
      <ContactTestPage />
    </>
  )
}

export {ContactTestWrapper}
